const linkToggle = document.querySelectorAll('.js-toggle');
for(let i = 0; i < linkToggle.length; i++){
    linkToggle[i].addEventListener('click', function(event){
        event.preventDefault();
        const span = this.querySelector('span');
        const container = document.getElementById(this.dataset.container);
        if (container.classList.contains('hidden')) {
            span.innerText = '–';
            container.classList.remove('hidden');
            container.style.height = 'auto';
            const height = container.clientHeight + 'px';
            container.style.height = '0px';
            setTimeout(function () {
                container.style.height = height;
            }, 0);
        } else {
            span.innerText = '+';
            container.style.height = '0px';
            container.addEventListener('transitionend', function () {
                container.classList.add('hidden');
            }, {
                once: true
            });
        }
    });
}