import LazyLoad from "vanilla-lazyload";
import Inputmask from "inputmask";
import * as L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";
import 'leaflet/dist/leaflet.css';
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import './slideToggle';
import AOS from 'aos';
import 'aos/dist/aos.css';
const axios = require('axios').default;
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
SwiperCore.use([Navigation, Pagination]);

require('blissfuljs');


window.lazyLoadInstance = new LazyLoad({
    elements_selector: '.lazy'
});

Inputmask({"mask": "+7 (999) 999-99-99"}).mask('input[type="tel"]');

window.map = L.map('map', {
    gestureHandling: true
}).setView([48.734282, 44.545575], 15);
L.tileLayer('https://a.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 18,
}).addTo(window.map);
L.marker([48.735318, 44.542353], {
    icon: L.icon({
        iconUrl: '../img/map-marker.png',
        iconSize:     [70, 96],
        iconAnchor:   [35, 96]
    })
}).addTo(window.map);

const swiper = new Swiper('#carousel', {
    loop: true,
    slidesPerView: 1,
    speed: 2000,

    breakpoints: {
        1200: {
            slidesPerView: 1.6667,
            centeredSlides: 1,
            speed: 2000,
            spaceBetween: 150,
        },
        1440: {
            slidesPerView: 1.6667,
            centeredSlides: 1,
            speed: 2000,
            spaceBetween: 200,
        },
        1600: {
            slidesPerView: 1.6667,
            centeredSlides: 1,
            speed: 2000,
            spaceBetween: 300,
        }
    },

    pagination: {
        el: '.carousel-pagination',
        clickable: true,
        bulletActiveClass: 'carousel-dot-active',
        bulletClass: 'carousel-dot',
        renderBullet: function (index, className) {
            return '<button class="' + className + '"><i class="fas fa-circle"></i></button>';
        }
    },

    navigation: {
        nextEl: '.carousel-button-next',
        prevEl: '.carousel-button-prev',
    },

    on: {
        init: function () {
            $$('#carousel img').forEach(function (element) {
                LazyLoad.load(element);
            });
        },
    },
});


$$('.header-link').forEach(function (element) {
    element._.bind({
        'click': function (event) {
            event.preventDefault();
            $(this.getAttribute('href')).scrollIntoView({behavior: "smooth"});
        }
    })
});


$(document)._.bind({
    'scroll': function (event) {
        const header = $('header');
        if (window.pageYOffset > 0) {
            header.classList.remove('bg-transparent');
            header.classList.add('bg-white');

            $$('a', header).forEach(function (element) {
                element.classList.add('text-blue');
                element.classList.add('hover:text-white');
                element.classList.remove('text-white');
            });
        } else {
            header.classList.remove('bg-white');
            $$('a', header).forEach(function (element) {
                element.classList.add('text-white');
                element.classList.remove('hover:text-white');
                element.classList.remove('text-blue');
            });
        }
    }
});


$('#show-modal-button')._.bind({
    'click': function (event) {
        event.preventDefault();
        this.blur();
        const parent = $('#modal');
        parent.classList.toggle('1000:-right-150');
        parent.classList.toggle('1000:-right-0');
        parent.classList.toggle('-right-full');
        parent.classList.toggle('-right-0');
    }
});
$('#close-modal-button')._.bind({
    'click': function (event) {
        event.preventDefault();
        $('#show-modal-button').click();
    }
});


$$('form').forEach(function (element) {
    element._.bind({
        'submit': function (event) {
            event.preventDefault();
            const err = $('.error-message')
            if (err !== null) {
                err.remove();
            }
            const form = this;
            const action = form.getAttribute('action');
            const formData = new FormData(form);
            let data = {};
            formData.forEach((value, key) => data[key] = value);
            axios.post(action, data)
                .then(function (response) {
                    if (response.data.result) {
                        form.innerHTML = '<p class="text-center font-bold text-blue text-2xl">Ваша заявка успешно отправлена</p>';
                    } else {
                        let message = document.createElement('p');
                        message.classList.add('error-message', 'text-blue', 'font-bold', 'text-blue', 'text-lg', 'my-6')
                        message.innerText = response.data.error;
                        form.insertAdjacentElement('beforebegin', message);
                    }
                })
                .catch(function (error) {
                    let message = document.createElement('p');
                    message.classList.add('text-blue', 'font-bold', 'text-blue', 'text-lg', 'my-6')
                    message.innerText = 'Произошла ошибка. Повторите попытку позже';
                    form.insertAdjacentElement('beforebegin', message);
                });
        }
    })
});


AOS.init({
    once: true,
    duration: 1500,
    disable: window.innerWidth < 768
});